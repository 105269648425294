import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import appReducer from "../reducers/rootReducer";

const middlewares = [thunk];
const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") return appReducer(undefined, action); // Reset all state
  return appReducer(state, action);
};

const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : {};

const store = createStore(
  rootReducer,
  { observer: persistedState.observer },
  composeWithDevTools(applyMiddleware(...middlewares))
);

// Save the observer state to local storage
store.subscribe(() => {
  localStorage.setItem(
    "reduxState",
    JSON.stringify({ observer: store.getState().observer })
  );
});

export default store;
