import { useSelector } from "react-redux";

function useObserverStatusByRole() {
  const hasObserver = useSelector(
    (state) => Object.keys(state.user?.data?.roles || {}).length > 1
  );
  return hasObserver ? true : false;
}

export default useObserverStatusByRole;
