import moment from "moment";
import "moment/locale/de"; // Load the German locale
import "moment-timezone";
import { twMerge } from "tailwind-merge";
import { clsx } from "clsx";
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

/*
 * Title: update object
 * Description: update object immutibly.
 * Author: Sohag
 */
export const updateObject = (oldState, updatedProperties) => {
  return {
    ...oldState,
    ...updatedProperties,
  };
};

// ref: https://momentjs.com/docs/#/displaying/format/
export const utcToGermanTime = (dateTime, formatString = "dd.MM.yyyy HH:mm:ss") => {
  if (!dateTime) return 'Invalid Date';
  try {
    const date = new Date(dateTime);
    if (date instanceof Date && !isNaN(date.getTime())) {
      return formatInTimeZone(date, 'Europe/Berlin', formatString, { locale: de });
    }
    return 'Invalid Date';
  } catch (error) {
    console.error("Error formatting date:", error);
    return 'Invalid Date';
  }
};

export const timeStampToGermanTime = (timestamp, formatPattern) => {
  const defaultFormat = "dd.MM.YYYY HH:mm:ss";
  return moment(timestamp)
    .tz("Europe/Berlin")
    .locale("de")
    .format(formatPattern || defaultFormat);
};

/* ==========================================
debouncer prevent unnecessary execution based on delay time
================================*/
export const debounce = (callback, delay = 250) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      timeoutId = null;
      callback(...args);
    }, delay);
  };
};

/* ==========================================
first or last character is whitespace or not checker
================================*/

export const StringWhitespaceCheck = (text) => {
  const isFirstCharacterWhitespace = /^\s/.test(text);
  const isLastCharacterWhitespace = /\s$/.test(text);
  const isWhitespace = isFirstCharacterWhitespace || isLastCharacterWhitespace;
  return isWhitespace;
};

/* ==========================================
set opacity of an element by index
================================*/

export const setOpacityByIndex = (index) => {
  if (index === 0) {
    return 0.2;
  }
  if (index === 1) {
    return 0.4;
  }
  if (index === 2) {
    return 0.6;
  }
  if (index === 3) {
    return 0.8;
  }
  if (index === 5) {
    return 1;
  }
};

//this file returns the file extension of a file name
export function getFileExtension(filename) {
  const parts = filename.split(".");
  if (parts.length > 1) {
    return parts[parts.length - 1];
  } else {
    return "No file extension found";
  }
}

//this function returns the 100 meters down's lat-lng
export function calculateNewLatLng(lat, lng) {
  // Earth's radius in meters
  const earthRadius = 6371000;
  const latRad = (lat * Math.PI) / 180;
  const newLatRad = latRad - 100 / earthRadius;
  const newLat = (newLatRad * 180) / Math.PI;
  return { lat: newLat, lng: lng };
}

export function getStartEndDateByTimeFrame(timeFrame) {
  const start = moment().subtract(timeFrame, "minutes");
  const end = moment().subtract(1, "minutes");

  //if current minutes more than 30, then increase 1 hour , else stick to current hour
  if (start.minutes() > 30) {
    start.add(1, "hour");
  }

  // set minues, seconds, milliseconds to 0
  start.minutes(0);
  start.seconds(0);
  start.milliseconds(0);

  return { start, end };
}

// cn is a tailwindcss utility class generator,
// cn name is popularized by shad-cn library
export const cn = (...inputs) => {
  return twMerge(clsx(inputs));
};
