import { useSelector } from "react-redux";
import useAgs from "./useAgs";
import { useEffect, useState } from "react";

// return role => users | rescue | managers
function useRole() {
  const [role, setRole] = useState("users");
  const ags = useAgs();
  const allAgsRoles = useSelector((state) => state.user?.data?.roles);
  useEffect(() => {
    if (!ags) return;
    if (!allAgsRoles) return;
    const roles = allAgsRoles[ags];

    const role = roles.includes("managers")
      ? roles[1]
      : roles.includes("rescue")
      ? roles[1]
      : roles[0];
    setRole(role);
  }, [ags, allAgsRoles]);
  return role;
}

export default useRole;

// import { useSelector } from "react-redux";

// // return role => users | rescue | managers
// function useRole() {
//   const role = useSelector((state) => state.login.data?.user?.role);

//   const ags = useAgs();
//   const allAgsRoles = useSelector((state) => state.user?.data?.roles);
//   useEffect(() => {
//     if (!ags) return;
//     const roles = allAgsRoles[ags];

//     const role = roles.includes("managers")
//       ? roles[1]
//       : roles.includes("rescue")
//       ? roles[1]
//       : roles[0];
//   }, [ags, allAgsRoles]);
//   return role;
// }

// export default useRole;
