import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../shared/Utility";

const initialState = {
  loading: false,
  error: null,
  data: null,
};

// reducer for hdcStatus
const hdcStatusPending = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
    data: null,
  });
};

const hdcStatusSuccess = (state, data) => {
  return updateObject(state, {
    loading: false,
    error: null,
    data: data,
  });
};

const hdcStatusFail = (state, error) => {
  return updateObject(state, {
    loading: false,
    data: null,
    error: error,
  });
};

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.HDC_STATUS_PENDING:
      return hdcStatusPending(state);
    case actionTypes.HDC_STATUS_SUCCESS:
      return hdcStatusSuccess(state, data);
    case actionTypes.HDC_STATUS_FAIL:
      return hdcStatusFail(state, error);
    default:
      return state;
  }
};

export default reducer;
