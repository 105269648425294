import axios from "axios";
import store from "../redux/store/store";
import { customErrorMessages } from "../shared/locales/CustomErrorMessages";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

export const reqInterceptor = instance.interceptors.request.use(
  (config) => {
    const token = store.getState().login?.data?.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const resInterceptor = instance.interceptors.response.use(
  (response) => {
    if (response.data?.message) {
      response.data.message =
        customErrorMessages[response.data.message] ?? response.data.message;
    }
    return response;
  },
  (error) => {
    if (error.response?.data?.message) {
      error.response.data.message =
        customErrorMessages[error.response.data.message] ??
        error.response.data.message;
    }
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location = "/login";
    } else {
      // handle all error except 401,404
      // store.dispatch(openErrorModal(error.message));
      return Promise.reject(error);
    }
  }
);

export default instance;
