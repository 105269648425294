import * as actionType from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const hdcStatusPending = () => {
  return {
    type: actionType.HDC_STATUS_PENDING,
  };
};

export const hdcStatusSuccess = (data) => {
  return {
    type: actionType.HDC_STATUS_SUCCESS,
    data: data,
  };
};

export const hdcStatusFail = (error) => {
  return {
    type: actionType.HDC_STATUS_FAIL,
    error: error,
  };
};

export const hdcStatus = () => {
  return async (dispatch) => {
    try {
      dispatch(hdcStatusPending());
      const response = await Axios.get("/malfunction/getOfflineStats");
      return dispatch(hdcStatusSuccess(response.data));
    } catch (error) {
      return dispatch(hdcStatusFail(error));
    }
  };
};
