import * as actionTypes from "../actionTypes";
import Axios from "../../../services/NetworkService";

export const externalfsInit = () => {
  return {
    type: actionTypes.EXTERNALFS_INIT,
  };
};

export const externalfsSuccess = (data) => {
  return {
    type: actionTypes.EXTERNALFS_SUCCESS,
    data: data,
  };
};

export const externalfsFail = (error) => {
  return {
    type: actionTypes.EXTERNALFS_FAIL,
    error: error,
  };
};

export const externalfs = (query) => {
  let urlEndPoint = "/externalfs";

  const queries = [];

  if (typeof query === "object") {
    Object.keys(query).forEach((k) => {
      queries.push(`${k}=${query[k]}`);
    });
  }

  if (queries.length > 0) {
    urlEndPoint += `?${queries.join("&")}`;
  }

  return (dispatch) => {
    dispatch(externalfsInit());
    Axios.get(urlEndPoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        dispatch(externalfsSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(externalfsFail(error));
      });
  };
};
