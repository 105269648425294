import { useSelector } from "react-redux";

// returns roles of all ags
function useRoles() {
  const roles = useSelector((state) => state.user?.data?.roles);
  return roles || {};
}

export default useRoles;

// import { useSelector } from "react-redux";

// // returns roles of all ags
// function useRoles() {
//   const roles = useSelector((state) => state.login.data?.user?.roles);
//   return roles;
// }

// export default useRoles;
